<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/wettkampf/${$route.params.veranstaltung}`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h2 class="white--text">Wettkampf bearbeiten</h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="veranstaltung.data">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl py-3"
        >
          <v-row justify="center">
            <v-col cols="10" class="px-2">
              <v-select
                filled
                rounded
                v-model="modus"
                :items="modi"
                label="Modus der Erfassung"
                v-on:change="update()"
              ></v-select>
              <v-select
                v-model="sportstaette"
                v-if="user.data.sportstaetten"
                :items="user.data.sportstaetten"
                item-text="name"
                item-value="name"
                filled
                rounded
                label="Sportstätte"
                v-on:change="update()"
              ></v-select>
              <v-text-field
                v-if="
                  veranstaltung.data.art == 'Wettkampf' ||
                  veranstaltung.data.art == 'Versammlung'
                "
                v-model="veranstaltung.data.name"
                filled
                rounded
                label="Name der Veranstaltung"
                v-on:change="update()"
              ></v-text-field>
              <v-dialog
                ref="beginndatum"
                v-model="beginndatum"
                :return-value.sync="veranstaltung.data.beginn.datum"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(veranstaltung.data.beginn.datum)"
                    label="Datum"
                    filled
                    rounded
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="de"
                  first-day-of-week="1"
                  v-model="veranstaltung.data.beginn.datum"
                  scrollable
                  v-on:change="update()"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="beginndatum = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="
                      $refs.beginndatum.save(veranstaltung.data.beginn.datum)
                      update()
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-dialog
                class="mb-4"
                ref="beginnuhrzeit"
                v-model="beginnuhrzeit"
                :return-value.sync="veranstaltung.data.beginn.uhrzeit"
                persistent
                width="290px"
                v-on:change="update()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="veranstaltung.data.beginn.uhrzeit"
                    label="Beginn"
                    filled
                    rounded
                    prepend-inner-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="veranstaltung.data.beginn.uhrzeit"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="beginnuhrzeit = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="
                      $refs.beginnuhrzeit.save(
                        veranstaltung.data.beginn.uhrzeit,
                      )
                      update()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-dialog
                ref="endedatum"
                v-model="endedatum"
                :return-value.sync="veranstaltung.data.ende.datum"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(veranstaltung.data.ende.datum)"
                    label="Bis Datum"
                    filled
                    rounded
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="de"
                  first-day-of-week="1"
                  v-model="veranstaltung.data.ende.datum"
                  scrollable
                  v-on:change="update()"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="beginndatum = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="
                      $refs.endedatum.save(veranstaltung.data.ende.datum)
                      update()
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="endeuhrzeit"
                v-model="endeuhrzeit"
                :return-value.sync="veranstaltung.data.ende.uhrzeit"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="veranstaltung.data.ende.uhrzeit"
                    label="Ende"
                    filled
                    rounded
                    prepend-inner-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="veranstaltung.data.ende.uhrzeit"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="endeuhrzeit = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="
                      $refs.endeuhrzeit.save(veranstaltung.data.ende.uhrzeit)
                      update()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-select
                filled
                rounded
                label="Nation erforderlich?"
                :items="[
                  { text: 'Ja', value: true },
                  { text: 'Nein', value: false },
                ]"
                v-model="veranstaltung.data.nation_required"
                @change="update()"
              ></v-select>
            </v-col>
            <v-col cols="10" class="px-2">
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                block
                large
                rounded
                :outlined="!start_delete"
                @click="start_delete ? delVeranstaltung() : del()"
              >
                {{
                  start_delete
                    ? 'Entfernen bestätigen'
                    : 'Veranstaltung entfernen'
                }}
              </v-btn>
            </v-col>
            <v-col cols="10" class="px-2" v-if="start_delete">
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                block
                large
                outlined
                rounded
                @click="start_delete = false"
              >
                Entfernen abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      app
      timeout="6000"
      v-model="saved"
      multi-line
      color="success"
      centered
      rounded="rounded-xl"
      top
    >
      <h2>
        <v-icon large class="mr-2">mdi-calendar-sync</v-icon>
        Veranstaltung gespeichert
      </h2>
      <template v-slot:action="{ attrs }">
        <v-btn
          :dark="!template.light"
          icon
          v-bind="attrs"
          @click="saved = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
//import firebase from 'firebase'
import { mapGetters } from 'vuex'
import store from '../../../../../store'
import router from '../../../../../routes/index'

export default {
  name: 'veranstaltung-bearbeiten',
  data() {
    return {
      dateFormatted: '',
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      endeuhrzeit: false,
      sportstaette: '',
      saved: false,
      edit: '',
      arten: [
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
        'Auswärtsspiel',
        'Training',
        'Wettkampf',
        'Versammlung',
      ],
      modus: '',
      modi: [
        'Athleten pro Disziplin erfassen',
        'Athleten für gesamten Wettkampf erfassen',
      ],
      teams: [],
      start_delete: false,
    }
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
    } else {
      store.dispatch('closeVeranstaltung')
    }
    this.sportstaette = this.veranstaltung.data.sportstaette
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Team')
      .orderBy('name')
      .get()
      .then((docRef) => {
        docRef.forEach((doc) => {
          this.teams.push(doc.data())
          var id = this.teams.length - 1
          this.teams[id].id = doc.id
        })
      })
      .catch((error) => {
        console.log(error)
      })
    if (this.veranstaltung.data) {
      if (this.veranstaltung.data.disziplinen) {
        this.modus = 'Athleten pro Disziplin erfassen'
      } else {
        this.modus = 'Athleten für gesamten Wettkampf erfassen'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },

  watch: {
    beginndate(val) {
      this.veranstaltung.data.beginn.datum = this.formatDate(this.date)
    },
    endedate(val) {
      this.veranstaltung.data.beginn.datum = this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    update() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.$route.params.veranstaltung)
        .update({
          art: this.veranstaltung.data.art,
          name:
            this.veranstaltung.data.art == 'Wettkampf' ||
            this.veranstaltung.data.art == 'Versammlung'
              ? this.veranstaltung.data.name
              : '',
          mannschaft:
            this.veranstaltung.data.art != 'Wettkampf'
              ? this.veranstaltung.data.mannschaft
              : '',
          gastmannschaft:
            this.veranstaltung.data.art == 'Freundschaftsspiel' ||
            this.veranstaltung.data.art == 'Meisterschaftsspiel' ||
            this.veranstaltung.data.art == 'Pokalspiel' ||
            this.veranstaltung.data.art == 'Auswärtsspiel'
              ? this.veranstaltung.data.gastmannschaft
              : '',
          beginn: this.veranstaltung.data.beginn,
          ende: this.veranstaltung.data.ende,
          maxzuschauer: this.veranstaltung.data.maxzuschauer,
          sportstaette: this.sportstaette,
          disziplinen:
            this.modus == 'Athleten pro Disziplin erfassen' ? true : false,
          nation_required: this.veranstaltung.data.nation_required,
        })
        .then((doc) => {
          this.saved = true
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
    del() {
      this.start_delete = true
    },
    delVeranstaltung() {
      var data = this.veranstaltung.data
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.$route.params.veranstaltung)
        .delete()
        .then(() => {
          if (data.gastverein) {
            firebase
              .firestore()
              .collection('User')
              .doc(data.gastverein.id)
              .collection('Veranstaltung')
              .doc(data.gastverein.veranstaltung)
              .delete()
              .then(() => {
                router.push('/start')
              })
          } else {
            router.push('/start')
          }
        })
    },
  },
}
</script>
